'use strict';

(function() {
  function SharedUrlResource($resource) {
    return $resource('api/v1/shared-url/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      
      addSharedUrl: {
        method: 'POST',
      },
    });
  }

  angular.module('windmanagerApp')
    .factory('SharedUrl', SharedUrlResource);
})();
